import React from 'react';
import "../../assets/css/style_New.css"
import { useNavigate } from 'react-router-dom';
import "../../assets/css/font-awesome.min.css"
import ImageComponent from '../../components/ImageCompone/ImageComponent';
import homebanner from "../../assets/images/logo.svg"
import { useTranslation } from "react-i18next";
function HomeScreen() {
    const navigate = useNavigate();
    const { t: customTranslationFunction } = useTranslation();
    // Navigate to Customer Details Screen
    const handleNavigations = () => {
        navigate('/customer-details');
    };
    return (
        <div className="home_bg">
            <div className="wrapper wrapper_bg">

                <div className="container">
                    <div className="innerWrapper">
                        <div className="bannerSection">
                            <h1 className="site-logo">
                                <ImageComponent src={homebanner} alt="OICARE" />

                            </h1>

                            <h2>{customTranslationFunction('AN_AFFORDABLE_HEALTHCARE_CREATED_BY_THE_PEOPLE_FOR_THE_PEOPLE')}</h2>

                            <h3>{customTranslationFunction('UNDOCUMENTED_CITIZENS_NON_CITIZENS_ARE_WELCOME_TO_JOIN_')}</h3>

                            <button type="button" className="btn-site" onClick={handleNavigations}>{customTranslationFunction('SHOP_FOR_PLAN')}</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default HomeScreen;
