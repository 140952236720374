export interface AboutYourSelfData {
    email: string;
    zipCode: string;
    startYear: string;
}

export const DefaultLocations = "US"
export const TabConstant = {
    MY_INFO: 'myInfo',
    BROWSWPLAN: 'browsePlan',
    JOINWAITLIST: 'joinWaitList',
};


export const FamilyPlanListConstant = {
    MYSELF: 'MYSELF',
    SPOUSE: 'SPOUSE',
    MY_FAMILY: 'MY FAMILY',
    MY_CHILDREN: 'MY CHILDREN',
    EXTENDED_FAMILY: 'EXTENDED FAMILY',
};
export const FamilyPlanListData = [
    'MYSELF',
    'SPOUSE',
    'MY FAMILY',
    'MY CHILDREN',
];

export const ExtendedFamilyPlanListConstant = {
    FATHER: 'FATHER',
    MOTHER: 'MOTHER',
    BROTHER: 'BROTHER',
    SISTER: 'SISTER',
    UNCLE: 'UNCLE',
    AUNT: 'AUNT',
    NEPHEW: 'NEPHEW',
    NIECE: 'NIECE',
    GRANDFATHER: 'GRANDFATHER',
    GRANDMOTHER: 'GRANDMOTHER'
};

export const ExtendedFamilyPlanList = [
    'FATHER',
    'MOTHER',
    'BROTHER',
    'SISTER',
    'UNCLE',
    'AUNT',
    'NEPHEW',
    'NIECE',
    'GRANDFATHER',
    'GRANDMOTHER',
];
export const GenderConstant = [{ gender: "Male" }, { gender: "Female" }];
export const yearOptions = ["2023", "2024", "2025", "2026"]
export const PlanOptions = ["Individuals & Families", "Employer-Provided"]
export interface userSelectedData {
    fieldName: string;
    DOB: string;
    Gender: string;
    Tobacco: string;
    price: number
}


export interface MemberShipInformation {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    planType: string;
    dateOfBirth: string;
    homeAddress: string;
    optionalAddress: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    referralCode: string;
}


