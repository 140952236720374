import React from 'react';
import { useTranslation } from 'react-i18next';

const InputComponent = ({ type, name, placeholder, value, onChange, pattern, className }: any) => {
    const { t: customTranslationFunction } = useTranslation();
    return (
        <input
            type={type}
            name={name}
            className={className ? `${className} form-control` : 'form-control'}
            placeholder={customTranslationFunction(placeholder)}
            value={value}
            onChange={onChange}
            pattern={pattern}
        />
    );
};

export default InputComponent;
