import React from 'react';
import "../../assets/css/style_New.css"
import "../../assets/css/font-awesome.min.css"
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import homebanner from "../../assets/images/only_icon.svg"
import ImageComponent from '../../components/ImageCompone/ImageComponent';
function ThankYou() {

    const { t: customTranslationFunction } = useTranslation();

    return (
        <div className="pagebox">
            <div className="wrapper wrapper_bg">
                <div className="container thankyouContainer">
                    <div className="innerWrapper" style={{ minWidth: "50%" }}>
                        <div className="thankYouWrapper">
                            <div className="titleSection text-center">
                                <ImageComponent src={homebanner} alt="OICARE" />
                                <h3 className=" thankyouTitle"><b>{customTranslationFunction('THANK_YOU_FOR_JOINING_OUR_WAITLIST')}</b></h3>
                                <p>{customTranslationFunction('PLEASE_KEEP_A_LOOK_OUT_FOR_ADDITIONAL_UPDATES_THAT_WILL_BE_ARRIVING_SOON_TO_THE_ADDRESS_YOU_PROVIDED')}</p>
                                <p style={{ fontWeight: 600 }}>{customTranslationFunction('SHARE_WITH_YOUR_FRIENDS_&_FAMILY')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThankYou;
