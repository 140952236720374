import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import BrowserRouter and wrap your Routes in it
import HomeScreen from './pages/Home/HomeScreen';
import CustomerDetails from './pages/CustomerDetails/CustomerDetail';
import Header from './components/HeaderComponents/HeaderComponents';
import MemberShip from './pages/MemberShip/MemberShip';
import ThankYou from './pages/ThankYou/ThankYou';

function App() {
  return (
    <div className='pagebox'>

      <Router>
        <Routes>
          <Route path="/" element={<WithNavbar component={HomeScreen} />} />
          <Route path="/customer-details" element={<WithNavbar component={CustomerDetails} />} />
          <Route path="/membership" element={<WithNavbar component={MemberShip} />} />
          <Route path="/thank-you" element={<WithNavbar component={ThankYou} />} />
        </Routes>
      </Router>
    </div>
  );
}

interface WithNavbarProps {
  component: React.ComponentType<any>;
}

function WithNavbar({ component: Component, ...rest }: WithNavbarProps) {
  return (
    <>
      <Header></Header>
      <Component {...rest} />
    </>
  );
}

export default App;
