import React, { useState } from 'react';
import logo from "../../assets/images/logo.svg"
import translate from "../../assets/images/translate.svg"
import ImageComponent from '../ImageCompone/ImageComponent';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "../../i18n"

function Header() {
    const [language, setLanguage] = useState('es')
    const navigate = useNavigate();
    const { t: customTranslationFunction, i18n } = useTranslation();
    const navigationHandle = () => {
        navigate('/')
    }
    const changeLanguage = () => {
        const newLanguage = language === 'en' ? 'es' : 'en';
        i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage);
    };
	const navigateToMemberShip=()=>{
		 navigate('/membership')
	}
    return (
        <div className="header">
            <div className="d-flex headerInner">
                <a href="#" className="logo" onClick={navigationHandle}>
                    <ImageComponent src={logo} alt="OICARE" />
                </a>
                <div className="actions d-flex">
                    <a href="#" className="btn-site btn-success" onClick={navigateToMemberShip}>{customTranslationFunction("JOIN_WAITLIST")}</a>
                    <a href="#" className="refresh-icon" onClick={() => changeLanguage()}>
                        <ImageComponent src={translate} alt="Language Change" />
                    </a>
                    <a href="#" className="btn-site"><i className="fa-regular fa-user d-none show-icon"></i> <span>{customTranslationFunction("SIGN_UP")}</span></a>

                </div>
            </div>
        </div>
    );
}

export default Header;
