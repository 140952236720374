import React, { useEffect, useState } from 'react';
import { AboutYourSelfData, yearOptions, userSelectedData, FamilyPlanListConstant, TabConstant, DefaultLocations, ExtendedFamilyPlanListConstant, ExtendedFamilyPlanList, FamilyPlanListData } from '../../constant/Constant';
import { emailCustom, zipCustom, planCustom, myInfoCustom, joinWaitListCustom, browsePlanCustom, myInfoSelectedCustom, browsePlanSelectedCustom, joinWaitListSelectedCustom, trashCustom, AddCustom, } from "../../constant/ImageConstant"
import InputComponent from '../../components/InputComponents/InputComponents';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import ImageComponent from '../../components/ImageCompone/ImageComponent';
import logo from "../../assets/images/ico_sq.svg"
import Accordion from '../../components/AccordionComponents/AccordionComponents';
import { useTranslation } from 'react-i18next';
import Api from "../../api/Api"


function CustomerDetails() {
    const navigate = useNavigate();
    const { t: customTranslationFunction } = useTranslation();
    const AccordionsData = [
        {
            title: customTranslationFunction('DOES_THIS_PLAN_GIVES_ME_WHAT_I_NEED_'),
            content: (
                <div>
                    <p>{customTranslationFunction('BASED_ON_YOUR_HEALTHCARE_PROFILE__WE_BELIEVE_THE_CURRENT_PREMIUM_QUOTE_PROVIDED_WOULD_GIVE_YOU_THE_MAXIMUM_COVERAGE_AT_AN_AFFORDABLE_PRICE_GLOBALLY_')}</p>
                    <p>{customTranslationFunction('WE_ALSO_BELIEVE_THAT_YOU_WOULD_BENEFIT_GREATLY_WITH_THIS_PLAN_IF_YOU_OR_ANYONE_WITHIN_YOUR_PLAN_DECIDE_TO_TRAVEL_ABROAD_AND_ENCOUNTERS_A_HEALTH_ISSUE_')}</p>
                    <p>{customTranslationFunction('ESTIMATED_ANNUAL_COST')} <b>({customTranslationFunction('THIS_WOULD_BE_A_LINK_TO_AUTOMATICALLY_SCROLL_DOWN_TO_THE_ESTIMATED_ANNUAL_COST_SECTION')})</b></p>
                </div>
            ),
        },
        {
            title: customTranslationFunction('INCLUDED_FOR_FREE'),
            content: (
                <div>
                    <p>{customTranslationFunction('ALL_HEALTH_PLANS_ARE_REQUIRED_BY_FEDERAL_LAW_TO_INCLUDE_SOME_FORM_OF_FREE_CARE_')}</p>
                    <p>{customTranslationFunction('THIS_PLAN_INCLUDES_THE_FOLLOWING_FOR_FREE_')}</p>
                    <ul className="dashed">
                        <li>{customTranslationFunction('PREVENTIVE_CARE_BENEFITS_FOR_YOU_')}</li>
                        <li>{customTranslationFunction('MOST_IMMUNIZATIONS_ARE_FREE__INCLUDING_ANNUAL_FLU_SHOTS_')}</li>
                        <li>{customTranslationFunction('FULL_BODY_MASSAGE__2_TIME_PER_YEAR_')}</li>
                    </ul>
                </div>
            ),
        },
        {
            title: customTranslationFunction('DOCTORS'),
            content: (
                <div>
                    <h4>{customTranslationFunction('DOCTOR_S_CHOICE')}</h4>

                    <div className="tablecontent">
                        <h4>{customTranslationFunction("PRIMARY_CARE_VISITS__IN___OUT_NETWORK_")}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>

                    <div className="tablecontent">
                        <h4>{customTranslationFunction('SPECIALIST__IN___OUT_NETWORK_')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>
                </div>
            ),
        },
        {
            title: customTranslationFunction('PRESCRIPTION_DRUGS'),
            content: (
                <div>
                    <div className="tablecontent">
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("GENERIC_DRUGS")}</span> <span>$0.00/$0.00&nbsp;{customTranslationFunction("REFILL")}</span></li>
                            <li><span>{customTranslationFunction("BRAND_DRUGS")}</span> <span>$0.00/$0.00&nbsp;{customTranslationFunction("REFILL")}</span></li>
                        </ul>
                    </div>

                    <div className="tablecontent">

                        <ul className="table-list">
                            <li><span>{customTranslationFunction("PREFERRED_BRAND")}</span> <span>$0.00/$0.00&nbsp;{customTranslationFunction("REFILL")}</span></li>
                            <li><span>{customTranslationFunction("SPECIALTY")}</span><span>$0.00/$0.00&nbsp;{customTranslationFunction("REFILL")}</span></li>
                        </ul>
                    </div>
                </div>
            ),
        },
        {
            title: customTranslationFunction('EMERGENCY_CARE'),
            content: (
                <div>
                    <div className="tablecontent">
                        <h4>{customTranslationFunction('EMERGENCY_ROOM')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>

                    <div className="tablecontent">
                        <h4>{customTranslationFunction('URGENT_CARE')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>
                </div>
            ),
        },
        {
            title: customTranslationFunction('SURGERY_TREATMENT'),
            content: (
                <div>
                    <div className="tablecontent">
                        <h4>{customTranslationFunction('INPATIENT')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>

                    <div className="tablecontent">
                        <h4>{customTranslationFunction('OUTPATIENT')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>
                    <div className="tablecontent">
                        <h4>{customTranslationFunction('IMAGINE')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>
                    <div className="tablecontent">
                        <h4>{customTranslationFunction('LABS')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>
                </div>
            ),
        },
        {
            title: customTranslationFunction('PREGNANCY'),
            content: (
                <div>
                    <div className="tablecontent">
                        <h4>{customTranslationFunction('PRENATAL_CARE')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>

                    <div className="tablecontent">
                        <h4>{customTranslationFunction('LABOR_AND_DELIVERY_FEES')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>
                    <div className="tablecontent">
                        <h4>{customTranslationFunction('POSTNATAL_CARE')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>
                </div>
            ),
        },
        {
            title: customTranslationFunction('VISION'),
            content: (
                <div>
                    <div className="tablecontent">
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("EYE_EXAM")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>
                    <div className="tablecontent">
                        <h4>{customTranslationFunction('GLASSES')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("GENERIC_GLASSES")}</span></li><br />
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li><br />

                            <li><span>{customTranslationFunction("BRAND_GLASSES")}</span></li><br />
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span className='amount'>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <br />
                            <li><span><b>({customTranslationFunction("MUST_INQUIRE_ON_ACCEPTABLE_BRAND")}) </b></span></li>
                        </ul>
                    </div>

                    <div className="tablecontent">
                        <h4>{customTranslationFunction('CONTACT')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li><br /><br />
                            <li><span>{customTranslationFunction("COLORED_CONTACT")}<br /><strong>({customTranslationFunction("NOT_INCLUDED_IN_PLAN")})</strong></span></li>
                        </ul>
                    </div>

                </div>
            ),
        },
        {
            title: customTranslationFunction("DENTAL"),
            content: (
                <div>
                    <div className="tablecontent">
                        <h4>{customTranslationFunction('CLEANING')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>

                    <div className="tablecontent">
                        <h4>{customTranslationFunction('DEEP_CLEANING')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>
                    <div className="tablecontent">
                        <h4>{customTranslationFunction('ROOT_CANAL')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>
                    <div className="tablecontent">
                        <h4>{customTranslationFunction('CROWN')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>
                    <div className="tablecontent">
                        <h4>{customTranslationFunction('TEETH_WHITENING')}</h4>
                        <ul className="table-list">
                            <li><span>{customTranslationFunction("BEFORE_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                            <li><span>{customTranslationFunction("AFTER_DEDUCTIBLE")}</span> <span>$0.00</span></li>
                        </ul>
                    </div>
                </div>
            ),
        },
        {
            title: customTranslationFunction("OUT_OF_NETWORK_COVERAGE"),
            content: (
                <div>
                    <p><strong>{customTranslationFunction('AT_THIS_TIME_ALL_MEDICAL_CARE_ARE_WITHIN_THIS_PLAN_S_NETWORK_UNTIL_FURTHER_UPDATES')}</strong></p>
                    <p><strong>{customTranslationFunction('EMERGENCY_ROOM_VISITS')}</strong></p>
                    <p>{customTranslationFunction('IF_YOU_NEED_IMMEDIATE_MEDICAL_ATTENTION_ANYWHERE_AT_THE_TIME_OF_EMERGENCY_EMERGENCY_SERVICES_TYPICALLY_BILLED_AT_AN_IN-NETWORK_RATE_AND_APPLIED_TOWARDS_THE_IN-NETWORK_DEDUCTIBLE')}</p>
                    <p><strong>{customTranslationFunction('SEPARATE_DEDUCTIBLE_AND_MAXIMUM_OUT_OF_POCKET')}</strong></p>
                    <p>{customTranslationFunction('THIS_PLAN_HAS_A_SEPARATE_DEDUCTIBLE_AND_MAXIMUM_OUT-OF-POCKET_FOR_OUT-OF-NETWORK_CARE')}{customTranslationFunction('THIS_MEANS_OUT-OF-NETWORK_SPENDING_DOES_NOT_COUNT_TOWARD_YOUR_IN-NETWORK_DEDUCTIBLE_AND_MAXIMUM_OUT-OF-POCKET')}</p>

                </div>
            ),
        },
    ];
    const [aboutYourSelfData, setAboutYourSelfData] = useState<AboutYourSelfData>({
        email: '',
        zipCode: '',
        startYear: '',
    });
    const [emailValid, setEmailValid] = useState(false);
    // ZipCode (Temporary Remove)
    // const [zipCodelValid, setZipCodelValid] = useState(false);
    const [datePickerErrorShow, setDatePickerErrorShow] = useState<boolean>(false)
    const [userDefaultLocations, setUserDefaultLocations] = useState(DefaultLocations);

    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [selectedExtendedFamilyPlanListItems, setSelectedExtendedFamilyPlanListItems] = useState<string[]>([]);
    const [selectedOption, setSelectedOption] = useState(TabConstant.MY_INFO);
    const [tabError, setTabError] = useState<string[]>([])
    const [userSelectedData, setUserSelectedData] = useState<userSelectedData[]>([])
    const [childLength, setChildLength] = useState<number>(0)
    const [planShow, setPlanShow] = useState<boolean>(false)
    const [planTotalAmount, setPlanTotalAmount] = useState<number>(0)
    const [planAccordionShow, setPlanAccordionShow] = useState<boolean>(false)
    const shouldShowFormFillSection = aboutYourSelfData.email !== '' && aboutYourSelfData.zipCode !== '' && emailValid && aboutYourSelfData.startYear !== '';//&& zipCodelValid Remove
    const [formattedDate, setFormattedDate] = useState('');
    const [minDate, setMinDate] = useState(new Date());
    useEffect(() => {
        getUserLocation()
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const newFormattedDate = `${month}/${day}/${year}`;
        const newMinDate = new Date(currentDate);
        newMinDate.setFullYear(currentDate.getFullYear() - 80);
        setFormattedDate(newFormattedDate);
        setMinDate(newMinDate);
    }, []);

    useEffect(() => {
        setTabError([])
        if (shouldShowFormFillSection) {
            setSelectedOption(TabConstant.BROWSWPLAN)
            setSelectedItems([...selectedItems, FamilyPlanListConstant.MYSELF]);
            let data: userSelectedData = {
                fieldName: FamilyPlanListConstant.MYSELF,
                DOB: new Date().toISOString(),
                Gender: "Female",
                Tobacco: "1",
                price: 0
            };
            setUserSelectedData((prevData) => [...prevData, data]);
        }
    }, [shouldShowFormFillSection,])

    // Get UserLocations
    function getUserLocation() {
        Api.get("email/location")
            .then((resp) => {
                if (resp?.statusCode === 200) {
                    setUserDefaultLocations(resp.data.country)
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    // Email Validations Check
    const isEmailValid = (email: string) => {
        const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailRegex.test(email);
    };

    // ZipCode Validation (Temporary Remove)
    // const isValidUSZIPCode = (zipCode: string) => {
    //     // Regular expressions for standard 5-digit ZIP code and ZIP+4 code
    //     const standardZIPPattern = /^\d{5}$/;
    //     const zipPlus4Pattern = /^\d{5}-\d{4}$/;

    //     return standardZIPPattern.test(zipCode) || zipPlus4Pattern.test(zipCode);
    // }

    // SelfData Set
    const handleSelfData = (event: any) => {
        const { name, value } = event.target;
        if (name === 'email') {
            if (!isEmailValid(value)) {
                setEmailValid(false);
            } else {
                setEmailValid(true);
            }
        }
        // ZipCode Validation (Temporary Remove)
        // else if (name === 'zipCode') {
        //     if (!isValidUSZIPCode(value)) {
        //         setZipCodelValid(false);
        //     } else {
        //         setZipCodelValid(true);
        //     }
        // }

        setAboutYourSelfData({
            ...aboutYourSelfData,
            [name]: value,
        });
    };

    useEffect(() => {
        if (selectedItems.includes(FamilyPlanListConstant.SPOUSE) && selectedItems.includes(FamilyPlanListConstant.MY_CHILDREN) && selectedItems.includes(FamilyPlanListConstant.MYSELF) && !selectedItems.includes(FamilyPlanListConstant.MY_FAMILY)) {
            setSelectedItems([...selectedItems, FamilyPlanListConstant.MY_FAMILY])
        }
    }, [selectedItems])

    useEffect(() => {
        const valuesToCheck = [FamilyPlanListConstant.MYSELF, FamilyPlanListConstant.SPOUSE, FamilyPlanListConstant.MY_CHILDREN];
        // setSelectedItems(selectedItems.filter((selectedItem: string) => valuesToCheck.includes(selectedItem)));

        let item = selectedItems.filter((selectedItem: string) => valuesToCheck.includes(selectedItem))
        // setSelectedItems(selectedItems.filter((selectedItem: string) => valuesToCheck.includes(selectedItem)));
        const elementsAreEqual = valuesToCheck.every(element => item.includes(element));
        if (elementsAreEqual) {
            console.log("Both arrays have the same elements.");
        } else {
            setSelectedItems(selectedItems.filter(element => element !== FamilyPlanListConstant.MY_FAMILY))
        }


    }, [userSelectedData])

    // FamilyPlanList Data add/Remove Manage
    const familyPlanListDataManage = (item: string) => {

        // Add tag for MY family

        if (item === FamilyPlanListConstant.MY_FAMILY) {
            if (selectedItems.includes(item)) {
                const isExtendedSelected = userSelectedData.filter(item => ExtendedFamilyPlanList.includes(item.fieldName));
                if (isExtendedSelected.length > 0) {
                    let updatedData = userSelectedData.filter(item => ![FamilyPlanListConstant.SPOUSE, FamilyPlanListConstant.MYSELF, FamilyPlanListConstant.MY_CHILDREN].includes(item.fieldName));
                    updatedData = updatedData.filter(element => !/Child/i.test(element.fieldName));
                    setUserSelectedData(updatedData)
                    setSelectedItems(selectedItems.filter((selectedItem: string) => ![FamilyPlanListConstant.SPOUSE, FamilyPlanListConstant.MYSELF, FamilyPlanListConstant.MY_CHILDREN, FamilyPlanListConstant.MY_FAMILY].includes(selectedItem)));
                    setChildLength(0)

                } else {
                    let updatedData = userSelectedData.filter(item => ![FamilyPlanListConstant.SPOUSE, FamilyPlanListConstant.MY_CHILDREN].includes(item.fieldName));
                    updatedData = updatedData.filter(element => !/Child/i.test(element.fieldName));
                    setUserSelectedData(updatedData)
                    setSelectedItems(selectedItems.filter((selectedItem: string) => ![FamilyPlanListConstant.SPOUSE, FamilyPlanListConstant.MY_CHILDREN, FamilyPlanListConstant.MY_FAMILY].includes(selectedItem)));
                    setChildLength(0)
                }

            } else {
                // Create a Set with unique values
                const uniqueValues = new Set([
                    ...selectedItems,
                    FamilyPlanListConstant.MYSELF,
                    FamilyPlanListConstant.SPOUSE,
                    FamilyPlanListConstant.MY_CHILDREN,
                    FamilyPlanListConstant.MY_FAMILY
                ]);
                setSelectedItems(Array.from(uniqueValues));
                const uniqueArray = Array.from(uniqueValues);
                for (const element of uniqueArray) {
                    const index = userSelectedData.findIndex(x => x.fieldName === element);
                    if (index === -1 && element !== FamilyPlanListConstant.MY_FAMILY) {
                        if (element === FamilyPlanListConstant.MY_CHILDREN) {
                            const childObjects = userSelectedData.filter(item => item.fieldName.startsWith("Child"));
                            if (childObjects.length === 0) {
                                const data = {
                                    fieldName: element !== FamilyPlanListConstant.MY_CHILDREN ? element : "Child 1",
                                    DOB: new Date().toISOString(),
                                    Gender: "Female",
                                    Tobacco: "1",
                                    price: 0
                                };
                                if (data.fieldName === "Child 1") {
                                    setChildLength(childLength + 1);
                                }
                                setUserSelectedData(prevData => [...prevData, data]);
                            }
                        } else {
                            if (element !== FamilyPlanListConstant.EXTENDED_FAMILY) {
                                const data = {
                                    fieldName: element !== FamilyPlanListConstant.MY_CHILDREN ? element : "Child 1",
                                    DOB: new Date().toISOString(),
                                    Gender: "Female",
                                    Tobacco: "1",
                                    price: 0
                                };
                                setUserSelectedData(prevData => [...prevData, data]);
                            }

                        }
                    }
                }
                // setUserSelectedData((prevData) => [...prevData, data]);
                // familyPlanListDataManage(FamilyPlanListConstant.MYSELF);
                // familyPlanListDataManage(FamilyPlanListConstant.SPOUSE);
                // familyPlanListDataManage(FamilyPlanListConstant.MY_CHILDREN);

            }

            return true;
        }
        if (selectedItems.includes(item)) {
            if (userSelectedData.length > 1) {
                if (item === FamilyPlanListConstant.EXTENDED_FAMILY) {
                    const isFamilyPlanData = userSelectedData.filter(item => FamilyPlanListData.includes(item.fieldName));
                    if (isFamilyPlanData.length !== 0) {
                        setUserSelectedData((prevData) => prevData.filter((data) => !selectedExtendedFamilyPlanListItems.includes(data.fieldName)));
                        setSelectedExtendedFamilyPlanListItems([]);
                        setSelectedItems(selectedItems.filter((selectedItem: string) => selectedItem !== item));
                    }
                } else if (item === FamilyPlanListConstant.MY_CHILDREN) {
                    let updatedData = userSelectedData.filter(element => !/Child/i.test(element.fieldName));
                    setUserSelectedData(updatedData)
                    setChildLength(0)
                    setSelectedItems(selectedItems.filter((selectedItem: string) => selectedItem !== item));

                } else {
                    setUserSelectedData((prevData) => prevData.filter((data) => data.fieldName !== item));
                    setSelectedItems(selectedItems.filter((selectedItem: string) => selectedItem !== item));
                }
            }

        } else {
            setSelectedItems((prevData) => [...prevData, item]);
            if (item !== FamilyPlanListConstant.EXTENDED_FAMILY) {
                let data: userSelectedData = {
                    fieldName: item !== FamilyPlanListConstant.MY_CHILDREN ? item : "Child 1",
                    DOB: new Date().toISOString(),
                    Gender: "Female",
                    Tobacco: "1",
                    price: 0
                };
                if (data.fieldName === "Child 1") {
                    setChildLength(childLength + 1)
                }

                setUserSelectedData((prevData) => [...prevData, data]);
            }
        }
    };

    // ExtendedFamilyPlanList Data add/Remove Manage
    const ExtendedFamilyPlanListToggleItem = (item: string) => {
        if (selectedExtendedFamilyPlanListItems.includes(item)) {
            if (userSelectedData.length > 1) {
                setSelectedExtendedFamilyPlanListItems(selectedExtendedFamilyPlanListItems.filter((selectedItem: string) => selectedItem !== item));
                setUserSelectedData((prevData) => prevData.filter((data) => data.fieldName !== item));
            }
        } else {
            setSelectedExtendedFamilyPlanListItems([...selectedExtendedFamilyPlanListItems, item]);
            let data: userSelectedData = {
                fieldName: item,
                DOB: new Date().toISOString(),
                Gender: "Female",
                Tobacco: "1",
                price: 0
            };
            setUserSelectedData((prevData) => [...prevData, data]);
        }

    };

    // All Data Reset
    const handleReset = () => {
        setSelectedItems([FamilyPlanListConstant.MYSELF]);
        let data: userSelectedData = {
            fieldName: FamilyPlanListConstant.MYSELF,
            DOB: new Date().toISOString(),
            Gender: "Female",
            Tobacco: "1",
            price: 0
        };
        setUserSelectedData((prevData) => [data]);

        setSelectedExtendedFamilyPlanListItems([])
        setChildLength(0)
    };

    // Tab Manage
    const handleTabManage = (option: any) => {
        if (!shouldShowFormFillSection) {
            const tabErrors: string[] = [];

            if (!aboutYourSelfData.email || !emailValid) {
                tabErrors.push("email");
            }
            if (!aboutYourSelfData.zipCode) { //|| !zipCodelValid Remove
                tabErrors.push("zipCode");
            }
            if (!aboutYourSelfData.startYear) {
                tabErrors.push("selectYear");
            }

            setTabError(tabErrors);
        } else {
            if (option === TabConstant.JOINWAITLIST && planShow) {
                const isAlDataFilled = validationsForData();
                if (isAlDataFilled) {
                    setSelectedOption(option);
                    navigate('/membership', {
                        state: {
                            userPlanData: userSelectedData,
                            selfData: aboutYourSelfData
                        }
                    });
                } else {
                    setDatePickerErrorShow(true)
                    setPlanShow(false)
                }
            } else {
                if (option === TabConstant.MY_INFO) {
                    setPlanShow(false)
                    setSelectedOption(option);
                } else {
                    if (option === TabConstant.JOINWAITLIST) {
                        // showPlanScreen()
                        gotoWaitList()
                    } else {
                        setPlanShow(true)
                        setSelectedOption(option);
                    }
                }
            }
        }
    };

    // Gender Manage
    const genderToggleSwitch = (index: any) => {
        const updatedData = [...userSelectedData];
        updatedData[index].Gender === "Male" ? updatedData[index].Gender = "Female" : updatedData[index].Gender = "Male"
        setUserSelectedData(updatedData);
    };
    // Tobbacco Field Manage
    const tobbaccoToggleSwitch = (index: number) => {
        const updatedData = [...userSelectedData];
        updatedData[index].Tobacco === "1" ? updatedData[index].Tobacco = "0" : updatedData[index].Tobacco = "1"
        setUserSelectedData(updatedData);
    };
    // Date Change
    const handleDateChange = (date: any, indexNumber: number) => {
        const updatedUserSelectedData = [...userSelectedData];
        updatedUserSelectedData[indexNumber].DOB = date.toISOString();
        setUserSelectedData(updatedUserSelectedData);
        // setStartDate(date);
    };
    // Add Child
    const addChildren = () => {
        let data: userSelectedData = {
            fieldName: "Child " + (childLength + 1),
            DOB: new Date().toISOString(),
            Gender: "Female",
            Tobacco: "1",
            price: 0
        };
        setChildLength(childLength + 1)
        setUserSelectedData((prevData) => [...prevData, data]);
    }
    // Remove Child
    const removeChildren = (indexNumber: number) => {
        // userSelectedData.splice(indexNumber, 1);
        //setUserSelectedData([...userSelectedData]);
        // Last Child Remove
        const lastChildIndex = userSelectedData.findIndex(element => element.fieldName === "Child " + childLength);
        if (lastChildIndex !== -1) {
            const updatedUserSelectedData = [...userSelectedData];
            updatedUserSelectedData.splice(lastChildIndex, 1);
            // userSelectedData.splice(lastChildIndex, 1);
            setUserSelectedData(updatedUserSelectedData)
            setChildLength(childLength - 1)
            if (childLength === 1) {
                setSelectedItems(selectedItems.filter((selectedItem: string) => selectedItem !== FamilyPlanListConstant.MY_CHILDREN));
            }
        } else {
            console.log("Child not found");
        }
    }
    // userSelectedData Validations Check
    function validationsForData() {
        for (const item of userSelectedData) {
            if (item.DOB === '') {
                return false;
            }
        }
        return true;
    }
    // User Age Calculate
    function calculateAge(dateOfBirth: any) {
        const dob = new Date(dateOfBirth);
        const currentDate = new Date();
        let age = currentDate.getFullYear() - dob.getFullYear();
        return age;
    }

    // Plan screen Show
    const showPlanScreen = () => {
        const isAlDataFilled = validationsForData();
        if (isAlDataFilled) {
            console.log("All 'DOB' fields are filled.", userSelectedData);
            for (const item of userSelectedData) {
                const age: any = calculateAge(item.DOB);
                // console.log(age, "age")
                if (age >= 20) {
                    // Location Wise Price Set
                    if (userDefaultLocations === DefaultLocations) {
                        // item.fieldName == FamilyPlanListConstant.MY_CHILDREN || item.fieldName === FamilyPlanListConstant.MY_FAMILY
                        // || item.fieldName === FamilyPlanListConstant.MY_SELF || item.fieldName === FamilyPlanListConstant.SPOUSE) {
                        // console.log("User is an adult for MYSELF");
                        item.price = 60
                    } else {
                        item.price = 40
                        // console.log("User is an adult for Extended Family");
                    }
                } else if (age >= 13 && age <= 17) {
                    item.price = 10
                    // console.log("User is a teenager");
                } else if (age >= 18 && age <= 19) {
                    item.price = 12
                    // console.log("User is young");
                } else if (age >= 0 && age <= 12) {
                    item.price = 5
                    // console.log("User is a kid");
                }
                setUserSelectedData((prevData) =>
                    prevData.map((prevItem) =>
                        prevItem.fieldName === item.fieldName ? { ...prevItem, price: item.price } : prevItem
                    )
                );
            }
            setPlanShow(!planShow)
            setDatePickerErrorShow(false)
            totalBillAmount()
        } else {
            setDatePickerErrorShow(true)
            setPlanShow(false)
            console.log("Not all 'DOB' fields are filled.");
        }
    }
    // selected Family Member Total Bill Ammount
    const totalBillAmount = () => {
        let totalPrice = 0;
        for (const item of userSelectedData) {
            totalPrice += item.price;
        }
        setPlanTotalAmount(totalPrice)
    }
    // Handle Navigations
    const gotoWaitList = () => {
        const isAlDataFilled = validationsForData();
        if (isAlDataFilled) {
            navigate('/membership', {
                state: {
                    userPlanData: userSelectedData,
                    selfData: aboutYourSelfData
                }
            });
        } else {
            setPlanShow(false)
            setDatePickerErrorShow(true)

        }
    }
    // Plan Show Accordion Screen
    const showPlanDetails = () => {
        setPlanAccordionShow(!planAccordionShow)
        setTimeout(() => {
            const accordionSection = document.getElementById("accordionSection");
            if (accordionSection) {
                accordionSection.scrollIntoView({ behavior: "smooth" });
            }
        }, 200);


    }


    return (
        <div className='pagebox'>
            <div className="wrapper wrapper_bg">
                <div className="container">
                    <div className="optionsBelt d-flex">
                        <div className={`option`}>
                            <a href="#" onClick={() => handleTabManage(TabConstant.MY_INFO)}>
                                <span className="icon">
                                    <div dangerouslySetInnerHTML={{ __html: selectedOption === TabConstant.MY_INFO ? myInfoSelectedCustom : myInfoCustom }} />
                                </span>
                                <h2 className={`${selectedOption === TabConstant.MY_INFO ? 'selected-option' : 'unSelected-option'}`}>{customTranslationFunction("MY_INFO")}</h2>
                            </a>
                        </div>

                        <div className={`option`}>
                            <a href="#" onClick={() => handleTabManage(TabConstant.BROWSWPLAN)}>
                                <span className="icon">
                                    <div dangerouslySetInnerHTML={{ __html: selectedOption === TabConstant.BROWSWPLAN ? browsePlanSelectedCustom : browsePlanCustom }} />
                                </span>
                                <h2 className={`${selectedOption === TabConstant.BROWSWPLAN ? 'selected-option' : 'unSelected-option'}`}>{customTranslationFunction("BROWSE_PLAN")}</h2>
                            </a>
                        </div>

                        <div className={`option`}>
                            <a href="#" onClick={() => handleTabManage(TabConstant.JOINWAITLIST)}>
                                <span className="icon">
                                    <div dangerouslySetInnerHTML={{ __html: selectedOption === TabConstant.JOINWAITLIST ? joinWaitListSelectedCustom : joinWaitListCustom }} />
                                </span>
                                <h2 className={`${selectedOption === TabConstant.JOINWAITLIST ? 'selected-option' : 'unSelected-option'}`}>{customTranslationFunction("JOIN_WAITLIST")}</h2>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="container">
                    {!planShow ? (
                        <React.Fragment>
                            <div className="contactSection">
                                <h2 className="site-title text-center">{customTranslationFunction('TELL_US_ABOUT_YOURSELF')}</h2>

                                <div className="contactBox d-flex">
                                    <div className="col">
                                        <div className="conContent">
                                            <div className="icon">
                                                <div dangerouslySetInnerHTML={{ __html: emailCustom }} />
                                            </div>
                                            <h3 className={tabError.includes('email') ? 'errorText' : ''}>{customTranslationFunction('YOUR_EMAIL_ADDRESS_')}*</h3>
                                            <InputComponent
                                                type="email"
                                                name="email"
                                                placeholder="EMAIL_ADDRESS"
                                                value={aboutYourSelfData.email}
                                                onChange={handleSelfData}
                                            />

                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="conContent">
                                            <div className="icon">
                                                <div dangerouslySetInnerHTML={{ __html: zipCustom }} />
                                            </div>
                                            <h3 className={tabError.includes('zipCode') ? 'errorText' : ''}>{customTranslationFunction('ZIP_CODE_POSTAL_CODE_')}*</h3>
                                            <InputComponent
                                                type="number"
                                                name="zipCode"
                                                placeholder="ZIP_POSTAL_CODE"
                                                value={aboutYourSelfData.zipCode}
                                                onChange={handleSelfData}
                                                pattern="[0-9]*"
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="conContent">
                                            <div className="icon">
                                                <div dangerouslySetInnerHTML={{ __html: planCustom }} />
                                            </div>
                                            <h3 className={tabError.includes('selectYear') ? 'errorText' : ''}>{customTranslationFunction('PLAN_START_YEAR')}*</h3>
                                            <select
                                                name="startYear"
                                                className="form-control selectdiv"
                                                value={aboutYourSelfData.startYear}
                                                onChange={handleSelfData}
                                            >
                                                <option value="">{customTranslationFunction("PLEASE_CHOOSE_THE_YEAR")}</option>
                                                {yearOptions.map((year) => (
                                                    <option key={year} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {shouldShowFormFillSection && (

                                <div className="formfillSection">
                                    <div className="container">
                                        <h3 className="site-title text-center">{customTranslationFunction('WHO_DO_YOU_WANT_TO_INCLUDE_IN_YOUR_PLAN_')}</h3>

                                        <div className="formRow d-flex">
                                            <div className="formCol text-center">
                                                <h4 className="text-center">{customTranslationFunction('YOU_CAN_SELECT_MULTIPLE')}</h4>
                                                <ul className="selectList">
                                                    {Object.entries(FamilyPlanListConstant).map(([key, value]) => {
                                                        return (
                                                            <li
                                                                key={key}
                                                                className={selectedItems.includes(value) ? 'selected' : ''}
                                                                onClick={() => familyPlanListDataManage(value)}
                                                            >
                                                                {customTranslationFunction(key)}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>

                                                <button type="button" className="btn-site btn-reverse" onClick={handleReset}>
                                                    {customTranslationFunction('RESET')}
                                                </button>
                                            </div>
                                            {selectedItems.includes(FamilyPlanListConstant.EXTENDED_FAMILY) && (
                                                <div className="formCol">
                                                    <h4 className="text-center">{customTranslationFunction('YOU_CAN_SELECT_MULTIPLE')}</h4>
                                                    <ul className="selectList">
                                                        {Object.entries(ExtendedFamilyPlanListConstant).map(([key, value]) => {
                                                            return (
                                                                <li
                                                                    key={key}
                                                                    className={selectedExtendedFamilyPlanListItems.includes(value) ? 'selected' : ''}
                                                                    onClick={() => ExtendedFamilyPlanListToggleItem(value)}
                                                                >
                                                                    {customTranslationFunction(key)}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>

                                                    {/* <button type="button" className="buttonFullWidth btn-site d-flex " onClick={showPlanScreen} ><b>CONTINUE</b> <i className="fa-solid fa-angle-right"></i></button> */}
                                                </div>
                                            )}
                                            <div className="formCol">
                                                <h4 className="text-center">{customTranslationFunction('THIS_INFORMATION_WILL_HELP_US_ESTIMATE_YOUR_COVERAGE_NEEDS_')}</h4>
                                                {userSelectedData.map((currentItems: userSelectedData, indexNumber: number) => {
                                                    if (currentItems.fieldName !== FamilyPlanListConstant.EXTENDED_FAMILY) {
                                                        return (
                                                            <div className="selfDetails" key={indexNumber}>
                                                                <div className="container-title">
                                                                    <h5>{customTranslationFunction(currentItems.fieldName !== FamilyPlanListConstant.MY_FAMILY ? currentItems.fieldName : "MY_FAMILY")}</h5>
                                                                    {currentItems?.fieldName.startsWith("Child") && (
                                                                        <div className="d-flex icon-container">
                                                                            <div onClick={() => addChildren()} dangerouslySetInnerHTML={{ __html: AddCustom }} />
                                                                            <div onClick={() => removeChildren(indexNumber)} dangerouslySetInnerHTML={{ __html: trashCustom }} />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="form-item d-flex nowrap">
                                                                    <label>{customTranslationFunction('DATE_OF_BIRTH')}</label>
                                                                    <div className="control">
                                                                        <DatePicker
                                                                            selected={currentItems?.DOB ? new Date(currentItems.DOB) : new Date()}
                                                                            onChange={(date) => handleDateChange(date, indexNumber)}
                                                                            placeholderText="MM/DD/YYYY"
                                                                            dateFormat="MM/dd/yyyy"
                                                                            className={(currentItems?.DOB || !datePickerErrorShow) ? '' : 'dateInputError'}
                                                                            minDate={minDate}
                                                                            maxDate={new Date()}
                                                                            peekNextMonth
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            scrollableYearDropdown={true}
                                                                            dropdownMode="select"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-item d-flex nowrap">
                                                                    <label>{customTranslationFunction('GENDER')}</label>
                                                                    <div className="control">
                                                                        <div className="btn-container">
                                                                            <div className="switch btn-color-mode-switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="color_mode"
                                                                                    id={`color_mode_${indexNumber}`}
                                                                                    value={currentItems.Gender === 'Male' ? 'Male' : 'Female'}
                                                                                    checked={currentItems.Gender === 'Male' ? false : true}
                                                                                    onChange={() => genderToggleSwitch(indexNumber)}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`color_mode_${indexNumber}`}
                                                                                    data-on={customTranslationFunction("FEMALE")}
                                                                                    data-off={customTranslationFunction("MALE")}
                                                                                    className="btn-color-mode-switch-inner"
                                                                                ></label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-item d-flex nowrap mb-0">
                                                                    <label className="font-normal">{customTranslationFunction('TOBACCO_USE_WITHIN_6_MONTH_')}:</label>
                                                                    <div className="control">
                                                                        <div className="btn-container">
                                                                            <div className="switch btn-color-mode-switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="color_mode"
                                                                                    id={`color_mode_tobacco_${indexNumber}`}
                                                                                    value={currentItems.Tobacco === '1' ? '1' : '0'}
                                                                                    checked={currentItems.Tobacco === '1' ? true : false}
                                                                                    onChange={() => tobbaccoToggleSwitch(indexNumber)}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`color_mode_tobacco_${indexNumber}`}
                                                                                    data-on={customTranslationFunction("NO")}
                                                                                    data-off={customTranslationFunction("YES")}
                                                                                    className="btn-color-mode-switch-inner"
                                                                                ></label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <button type="button" className="buttonFullWidth btn-site d-flex marginBottom" onClick={showPlanScreen} ><b>{customTranslationFunction('CONTINUE')}</b> <i className="fa-solid fa-angle-right"></i></button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>

                    ) : (
                        <>
                            <div className="container">
                                <div className="contactSection">

                                    <div className="row planROw">
                                        <div className="col formCol text-center">
                                            <div className="covered_plan">

                                                <h3><b>{customTranslationFunction('PEOPLE_COVERED_BY_THIS_HEALTH_PLAN')}</b></h3>

                                                <ul className="selectList">
                                                    {userSelectedData.map((currentItems: userSelectedData, indexNumber: number) => {
                                                        return (
                                                            <li key={indexNumber}>{customTranslationFunction(currentItems.fieldName !== FamilyPlanListConstant.MY_FAMILY ? currentItems.fieldName : "MY_FAMILY")}</li>
                                                        )
                                                    })}
                                                </ul>

                                                <h4>{customTranslationFunction('DO_YOU_WANT_TO_COVER_ADDITIONAL_FAMILY_MEMBERS_')}</h4>

                                                <a href="#" className="btn-site d-flex" onClick={showPlanScreen}><b>{customTranslationFunction('CLICK_HERE')}</b> <i className="fa-solid fa-angle-right"></i></a>
                                                {/* <button type="button" className="buttonFullWidth btn-site d-flex "  ><b>Click Here</b> <i className="fa-solid fa-angle-right"></i></button> */}

                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="planRollbox">
                                                <div className="content">
                                                    <div className="icon">
                                                        <ImageComponent src={logo} alt="OICARE" />

                                                    </div>

                                                    <h3 className="text-center">{customTranslationFunction('BEST_PREMIUM_AVAILABLE')}</h3>

                                                    <h4 className="price text-center"><b>${planTotalAmount}.00</b>/{customTranslationFunction('MONTH')}</h4>

                                                    <ul>
                                                        <li><span>Deductible:</span> <b>$0.00</b></li>
                                                        <li><span>OOP:</span> <b>$0.00</b></li>
                                                        <li><span>Generic Drugs:</span> <b>$0.00</b></li>
                                                        <li><span>Doctors:</span> <b>$0.00</b></li>
                                                        <li><span>Co-Pay:</span> <b>$0.00</b></li>
                                                    </ul>

                                                    <div className="text-center contact_dt">
                                                        <p>E-mail: <b>{aboutYourSelfData?.email}</b></p>
                                                        <p>Date: <b>{formattedDate}</b></p>
                                                    </div>

                                                    <p className="text-center"><b>{customTranslationFunction('GET_15_DISCOUNT_ON_YOUR_FIRST_YEAR_WHEN_YOU_JOIN_THE_WAITLIST_')}</b></p>
                                                </div>
                                            </div>

                                            <div className="btn-row d-flex text-center">
                                                {/* <a href="#" className="btn btn-site btn-site-success" onClick={gotoWaitList}>JOIN WAITLIST</a> */}
                                                <button type="button" className="btn btn-site btn-site-success" onClick={gotoWaitList} >{customTranslationFunction('JOIN_WAITLIST')}</button>
                                                {/* <a href="#" className="btn btn-site" onClick={showPlanDetails}>VIEW PLAN</a> */}
                                                <button type="button" className=" btn btn-site" onClick={showPlanDetails} >{customTranslationFunction('VIEW_PLAN')}</button>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {planAccordionShow ? (
                                    <div className="accordianSection" id="accordionSection">
                                        <h2 className="text-center">{customTranslationFunction('PREMIUM_DETAILS')}</h2>
                                        <ul className="accordion-list">
                                            {AccordionsData.map((item, index) => (
                                                <Accordion key={index} title={item.title} content={item.content} />
                                            ))}
                                        </ul>
                                    </div>
                                ) : null}
                            </div>

                        </>
                    )}
                </div>


            </div>
        </div >
    );
}

export default CustomerDetails;
