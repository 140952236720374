class Api {
    static headers(token: any) {
        return {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
    }
    static async post(route: any, params: any) {
        let v = await this.oIcareTask(route, params, {
            'Content-Type': 'application/json',
        }, 'POST');
        console.log('---v----', v)
        return v;
    }
    static async get(route: any) {
        let v = await this.oIcareTask(route, null, {}, 'GET');
        return v;
    }

    static oIcareTask(route: any, params: any, header: any, verb: any) {
        const host = 'https://api.oicares.com/';
        // const host = 'https://2c6e-203-109-79-20.ngrok.io/';

        const url = `${host}${route}`;
        // console.log('URL CALLLED-----', url);
        const requestOptions: any = {
            method: verb,
            headers: header,
            body: params,
            redirect: 'follow'
        };
        return fetch(url, requestOptions)
            .then(resp => {
                // console.log('----RESPONSE----', resp);
                const json = resp.json();
                if (resp.ok) {
                    //console.log('resp ok', resp.ok)
                    return json;
                }
                if (resp.status === 403) {
                    return { auth: 'invaild' };
                    //console.log('resp.status', resp.status)
                } else if (resp.status === 401) {
                    console.log("test");
                    // ActionCreators.logoutWithoutToken();
                }
                else if (resp.status === 404) {
                    return resp.status;
                }
                return json.then(err => {
                    // //console.log('resp.err',err)
                    throw err;

                });
            })
            .then((json) => json)
            .catch(error => {
                return error?.response;
            });
    }


}
export default Api;