import React, { useEffect, useState } from 'react';
import "../../assets/css/style_New.css"
import "../../assets/css/font-awesome.min.css"
import { useLocation } from 'react-router-dom';
import InputComponent from '../../components/InputComponents/InputComponents';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { AboutYourSelfData, FamilyPlanListConstant, MemberShipInformation, PlanOptions, userSelectedData } from '../../constant/Constant';
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import Api from "../../api/Api"
import { useNavigate } from 'react-router-dom';
function MemberShip() {
    const location = useLocation();
    const navigate = useNavigate();
    const { t: customTranslationFunction } = useTranslation();
    const encryptionKey = "224541-OICARE-hasd21545452";

    const [userPlanData] = useState<userSelectedData[]>(location?.state?.userPlanData);
    const [userSelfData] = useState<AboutYourSelfData>(location?.state?.selfData)
    const [formData, setFormData] = useState<MemberShipInformation>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        planType: '',
        dateOfBirth: '',
        homeAddress: '',
        optionalAddress: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        referralCode: ''
    });
    const [fieldValidation, setFieldValidation] = useState<boolean>(false)
    const [minDate, setMinDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        //if (!location?.state) {
        //  navigate('/customer-details');
        //}
        const currentDate = new Date();
        const newMinDate = new Date(currentDate);
        newMinDate.setFullYear(currentDate.getFullYear() - 80);
        setMinDate(newMinDate);

        const updatedFormData = { ...formData };
        let selfData: any = userPlanData?.find(item => item["fieldName"] === FamilyPlanListConstant.MYSELF);
        updatedFormData.email = userSelfData?.email;

        updatedFormData.zipCode = userSelfData?.zipCode;
        updatedFormData.dateOfBirth = selfData?.DOB
        //updatedFormData.dateOfBirth=
        setFormData(updatedFormData);
    }, []);

    // MemberShip Data Fill
    const memberShipInputHandle = (e: any) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    // Date Change
    const handleDateChange = (date: any) => {
        const updatedUserSelectedData = { ...formData };
        updatedUserSelectedData.dateOfBirth = date.toISOString();
        setFormData(updatedUserSelectedData);
        // setStartDate(date);
    };
    const isRequiredValid = (value: any) => value?.trim() !== '';
    const isEmailValid = (value: any) => {
        const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailRegex.test(value);
    };
    const isPhoneNumberValid = (value: any) => {
        const numericRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        return numericRegex.test(value);
    };

    const isPlanTypeValid = (value: any) => {
        return value !== '';
    };
    const fieldValidations = {
        firstName: isRequiredValid,
        lastName: isRequiredValid,
        email: isEmailValid,
        phoneNumber: isPhoneNumberValid,
        planType: isRequiredValid,
        dateOfBirth: isRequiredValid,
        homeAddress: isRequiredValid,
        city: isRequiredValid,
        state: isRequiredValid,
        country: isRequiredValid,
        zipCode: isRequiredValid,
    };

    // data Submit and Data Encryptions
    const submitData = async () => {
        setLoading(true);
        let isValid = true;
        // Validations Check
        for (const field in fieldValidations) {
            const fieldName = field as keyof typeof fieldValidations;
            if (!fieldValidations[fieldName](formData[fieldName])) {
                isValid = false;
                setFieldValidation(true);
                console.error(`Validation failed for ${fieldName}`);
            }
        }

        if (isValid) {
            try {

                console.log("Form data is valid, submitting...");
                const submitedData = {
                    memberShipformData: formData,
                    customerSelfData: userSelfData,
                    customerPlanData: userPlanData
                }
                const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(submitedData), encryptionKey).toString();
                const requestData = { message: ciphertext };
                const response = await Api.post("email/send", JSON.stringify(requestData));
                if (response.statusCode === 200) {
                    setLoading(false)
                    // setLocation(response.data.country);
                    navigate('/thank-you')
                } else {
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        } else {
            setLoading(false);
            setFieldValidation(true)
            console.error("Some fields contain errors. Please check the form.");
        }
    };
    return (
        <div className="pagebox">
            <div className="wrapper wrapper_bg">

                <div className="container">

                    <div className="innerWrapper">
                        <div className="titleSection text-center">
                            <h1 className="site-title">{customTranslationFunction('MEMBERSHIP_INFORMATION')}</h1>
                            <p>{customTranslationFunction('THE_INFORMATION_YOU_ENTER_SHOULD_MATCH_WHAT_WAS_PROVIDED_DURING_PLAN_ENROLLMENT_')}</p>
                            <p className="subtext">{customTranslationFunction('ALL_FIELDS_ARE_REQUIRED_UNLESS_OTHERWISE_SPECIFIED')}</p>
                        </div>

                        <div className="membershipContainer">
                            <div className="membershipformRow d-flex">

                                <div className="formCol">
                                    <div className="box">
                                        <form action="">
                                            <div className="form-group">
                                                <label>{customTranslationFunction('FIRST_NAME')}</label>
                                                <InputComponent
                                                    type="text"
                                                    name="firstName"
                                                    placeholder="ENTER_FIRST_NAME"
                                                    value={formData.firstName}
                                                    onChange={memberShipInputHandle}
                                                    className={fieldValidation && !isRequiredValid(formData.firstName) ? 'dateInputError' : ''}

                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>{customTranslationFunction('LAST_NAME')}</label>
                                                <InputComponent
                                                    type="text"
                                                    name="lastName"
                                                    placeholder="ENTER_LAST_NAME"
                                                    value={formData.lastName}
                                                    onChange={memberShipInputHandle}
                                                    className={fieldValidation && !isRequiredValid(formData.lastName) ? 'dateInputError' : ''}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>{customTranslationFunction('EMAIL')}</label>
                                                <InputComponent
                                                    type="email"
                                                    name="email"
                                                    placeholder="ENTER_EMAIL"
                                                    value={formData.email}
                                                    onChange={memberShipInputHandle}
                                                    className={fieldValidation && !isEmailValid(formData.email) ? 'dateInputError' : ''}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>{customTranslationFunction('PHONE_NUMBER')}</label>
                                                <InputComponent
                                                    type="tel"
                                                    name="phoneNumber"
                                                    placeholder="ENTER_PHONE_NUMBER"
                                                    value={formData.phoneNumber}
                                                    onChange={memberShipInputHandle}
                                                    className={fieldValidation && !isPhoneNumberValid(formData.phoneNumber) ? 'dateInputError' : ''}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>{customTranslationFunction('PLAN_TYPE')}</label>
                                                {/* <select className="form-control">
                                                    <option>Make a selection</option>
                                                    <option>Individuals & Families</option>
                                                    <option>Employer-Provided</option>
                                                </select> */}

                                                <select
                                                    name="planType"
                                                    className={fieldValidation && !isPlanTypeValid(formData.planType) ? 'form-control dateInputError' : 'form-control'}
                                                    value={formData.planType}
                                                    onChange={memberShipInputHandle}
                                                >
                                                    <option value="">{customTranslationFunction('MAKE_A_SELECTION')}</option>
                                                    {PlanOptions.map((plan) => (
                                                        <option key={plan} value={plan}>
                                                            {plan}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label>{customTranslationFunction('DATE_OF_BIRTH')}</label>
                                                <DatePicker
                                                    portalId="root"
                                                    selected={formData?.dateOfBirth ? new Date(formData?.dateOfBirth) : new Date()}
                                                    onChange={(date) => handleDateChange(date)}
                                                    placeholderText="MM/DD/YYYY"
                                                    dateFormat="MM/dd/yyyy"
                                                    className={fieldValidation && !isRequiredValid(formData.dateOfBirth) ? 'dateInputError' : ''}
                                                    minDate={minDate}
                                                    maxDate={new Date()}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown={true}
                                                    dropdownMode="select"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="formCol">
                                    <div className="box">
                                        <form action="">
                                            <div className="form-group">
                                                <label>{customTranslationFunction('FULL_PHYSICAL_ADDRESS_HOME_ADDRESS_')}</label>
                                                <InputComponent
                                                    type="text"
                                                    name="homeAddress"
                                                    placeholder="HOME_ADDRESS"
                                                    value={formData.homeAddress}
                                                    onChange={memberShipInputHandle}
                                                    className={fieldValidation && !isRequiredValid(formData.homeAddress) ? 'dateInputError' : ''}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>{customTranslationFunction('FULL_PHYSICAL_ADDRESS_OPTIONAL_')}</label>
                                                <InputComponent
                                                    type="text"
                                                    name="optionalAddress"
                                                    placeholder="HOME_ADDRESS"
                                                    value={formData.optionalAddress}
                                                    onChange={memberShipInputHandle}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>{customTranslationFunction('CITY')}</label>
                                                <InputComponent
                                                    type="text"
                                                    name="city"
                                                    placeholder="CITY"
                                                    value={formData.city}
                                                    onChange={memberShipInputHandle}
                                                    className={fieldValidation && !isRequiredValid(formData.city) ? 'dateInputError' : ''}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>{customTranslationFunction('STATE')}</label>
                                                <InputComponent
                                                    type="text"
                                                    name="state"
                                                    placeholder="STATE"
                                                    value={formData.state}
                                                    onChange={memberShipInputHandle}
                                                    className={fieldValidation && !isRequiredValid(formData.state) ? 'dateInputError' : ''}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>{customTranslationFunction('COUNTRY')}</label>
                                                <InputComponent
                                                    type="text"
                                                    name="country"
                                                    placeholder="COUNTRY"
                                                    value={formData.country}
                                                    onChange={memberShipInputHandle}
                                                    className={fieldValidation && !isRequiredValid(formData.country) ? 'dateInputError' : ''}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>{customTranslationFunction('ZIP_CODE_POSTAL_CODE_')}</label>
                                                <InputComponent
                                                    type="number"
                                                    name="zipCode"
                                                    placeholder="ZIP_CODE_POSTAL_CODE_"
                                                    value={formData.zipCode}
                                                    onChange={memberShipInputHandle}
                                                    className={fieldValidation && !isRequiredValid(formData.zipCode) ? 'dateInputError' : ''}

                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>{customTranslationFunction('REFERRAL_CODE')}</label>
                                                <InputComponent
                                                    type="text"
                                                    name="referralCode"
                                                    placeholder="REFERRAL_CODE"
                                                    value={formData.referralCode}
                                                    onChange={memberShipInputHandle}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <button type="button" className="btn-site" onClick={submitData} disabled={loading}>
                                    {loading ? "Loading..." : customTranslationFunction('JOIN_WAITLIST')}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default MemberShip;
