import React, { useState } from 'react';

const Accordion = ({ title, content }: any) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    }
    return (
        <li className={isOpen ? 'active' : ''} onClick={toggleAccordion}>
            <h3>{title}</h3>
            {isOpen &&
                <div className={`answer ${isOpen ? 'open' : ''}`}>
                    {content}
                </div>
            }
        </li>
    );
};

export default Accordion;
